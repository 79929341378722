<template>
    <el-table v-if="data.length > 0" :data="data">
        <!-- <hide-at breakpoint="mediumAndBelow"> -->
        <!-- <el-table-column label="id" prop="id" width="60"></el-table-column> -->
        <!-- </hide-at> -->
        <el-table-column :label="$t('clients.custom_id')" prop="custom_id" sortable width="100" />
        <el-table-column :label="$t('clients.name')" prop="name" sortable :formatter="nameFormatter" />
        <el-table-column :label="$t('clients.type')" prop="type" sortable width="100" :formatter="typeFormatter" />
        <el-table-column width="100">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <router-link :to="`/clients/${scope.row.uuid}/dashboard`" class="el-button el-tooltip el-button--primary el-button--mini">
                            <fa-icon :icon="['fas', 'info']" fixed-width />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.bring_back')" placement="top">
                        <el-button type="warning" size="mini" @click="handleRestore(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'redo']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.delete')" placement="top">
                        <el-button type="danger" size="mini" @click="handleDelete(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        nameFormatter(row) {
            return `${row.name} ${row.surname}`;
        },

        typeFormatter(row) {
            return this.$t(`clients.${row.type}`);
        },

        handleDelete(value) {
            this.$emit('delete', value);
        },

        handleRestore(value) {
            this.$emit('restore', value);
        },
    },
};
</script>
